import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import API from '../../config/config';
import Link from 'next/link';
import Head from 'next/head';

declare const window: any;
const Footer = ({ data }: any) => {
  let arr: any = [];
  // const [email, setEmail] = useState('');
  // const [subscription, setSubscription] = useState(false);
  // const [loader, setLoader] = useState(false);
  const windowSize = useWindowSize();
  function useWindowSize() {
    const [dimension, setDimensions] = useState({
      height: undefined,
      width: undefined
    });

    useEffect(() => {
      if (window.affirm && window.affirm.ui) {
        try {
          window.affirm.ui.refresh();
        } catch (err) {
        }
      }
    }, []);

    useEffect(() => {
      const handleResize = () => {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth
        });
      };
      window.addEventListener('resize', handleResize);
      handleResize()
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    return dimension
  }

  // const subscribe = (event: any) => {
  //   event.preventDefault();
  //   const form = event.currentTarget;
  //   if (form.checkValidity()) {
  //     setLoader(true);
  //     httpService.invokeApi(
  //       { url: API.endpoints.subscriptions, method: 'post', data: { emailId: email } },
  //       (success: boolean, response: any) => {
  //         if (success && response.id) {
  //           setSubscription(true);
  //         }
  //         setLoader(false);
  //       }
  //     );
  //   }
  // };

  const footerSection = (item: any, j: number) => {
    if (item.__component === 'footer.section') {
      arr.push(j);
      return (
        <div>
          <h6 className="line-wrap">{item.heading}</h6>
          {item.Links.map((links: any, i: number) => {
            if (links.text === 'Financing') {
              return (
                <p key={i} className="line-wrap affirm-site-modal">
                  {links.text}
                </p>
              );
            } else {
              return (
                <p key={i}>
                  <Link href={links.url}>
                    <a className="line-wrap">{links.text}</a>
                  </Link>
                </p>
              );
            }
          })}
        </div>
      );
    }
  };

  const subscriberSection = (data: any) => {
    return (
      <div className={'center-align-footer '}>
        {<div className={windowSize.width > 768 ? 'show' : 'hide'}>{mobileSubscribeSection(data)}</div>}
        <Row>
          <div className="logo-footer-container">
            {data.SocialMediaIcons.map((item: any, i: number) => {
              return (
                <a key={i} href={item.url} target="_blank" rel="noopener noreferrer">
                  <img
                    aria-label={API.baseUrl + item.icon.url}
                    src={item.icon ? API.baseUrl + item.icon.url : ''}
                    alt={item.icon ? item.icon.alternativeText : ''}
                  />
                </a>
              );
            })}
          </div>
        </Row>
      </div>
    );
  };

  const mobileSubscribeSection = (data: any) => {
    return (
      data && (
        <div className={'center-align-footer '}>
          <div className="klaviyo-form-UHiQNK" />
          <Head>
            <script
              async
              type="text/javascript"
              src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=J9rJWE"
            />
          </Head>
        </div>
      )
    );
  };

  const privacysection = (data: any) => {
    return (
      data && (
        <div
          className="privacy-footer"
          style={{ backgroundImage: `url(${data.backgroundImage ? API.baseUrl + data.backgroundImage.url : ''} )` }}
        >
          <div className="display-flex">
            <div>
              <div className="privacy-footer-span-left">
                {data.Links.map((links: any, i: number) => {
                  return (
                    <Link href={links.url} key={i}>
                      <a>{links.text}{i < data.Links?.length -1 && <span className="link-divider">|</span>}</a>
                    </Link>
                  );
                })}
              </div>
            </div>
            <div>
              <span className="privacy-footer-span-right ">{data.copyrightText}</span>
            </div>
          </div>
        </div>
      )
    );
  };

  return (
    data && (
      <div className="app-footer">
        <div className={data[0].__component === 'footer.privacy-section' ? '' : 'footer-container'}>
          <Row xl={4} md={2}>
            {data.map((item: any, i: any) => {
              arr = [];
              switch (item.__component) {
                case 'footer.subscribe-section':
                  return (
                    <Col key={i} xl={5} className={'mobileview ' + (windowSize.width <= 768 ? 'show' : 'hide')}>
                      {mobileSubscribeSection(item)}
                    </Col>
                  );
                default:
                  return null;
              }
            })}
            {data.map((col: any, i: number) => {
              switch (col.__component) {
                case 'footer.section':
                  /** checking whether its printed or not */
                  for (let j = 0; j < arr.length; j++) {
                    if (i === arr[j]) {
                      return null;
                    }
                  }
                  return (
                    <Col key={i} xl={2}>
                      <div className="footer-sub-text">
                        {footerSection(col, i)}
                        {footerSection(data[i + 1], i + 1)}
                      </div>
                    </Col>
                  );
                case 'footer.subscribe-section':
                  return (
                    <Col key={i} xl={5}>
                      {subscriberSection(col)}
                    </Col>
                  );
                default:
                  return null;
              }
            })}
          </Row>
        </div>
        <div>
          {data.map((col: any, i: number) => {
            switch (col.__component) {
              case 'footer.privacy-section':
                return <div key={i}>{privacysection(col)}</div>;
              default:
                return null;
            }
          })}
        </div>
      </div>
    )
  );
};

export default Footer;
